import Cookies from 'js-cookie'

const cookieName = '__fltfl_utms'

export const utmParamsList = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_camid',
  'campaignid'
]

export const storeUtmParams = () => {
  if (typeof window === 'undefined') return

  const urlParams = new URLSearchParams(window.location.search)
  let existingUtmData = {}

  // Safely parse existing cookie data
  try {
    const cookieValue = Cookies.get(cookieName)
    if (cookieValue) {
      existingUtmData = JSON.parse(cookieValue)
    }
  } catch (error) {
    console.error('Failed to parse UTM cookie:', error)
  }

  const newUtmData = { ...existingUtmData } // Preserve existing UTMs

  let hasNewUtmParams = false

  utmParamsList.forEach(param => {
    const paramValue = urlParams.get(param)
    if (paramValue) {
      hasNewUtmParams = true
      newUtmData[param] = paramValue // Overwrite existing or add new
    }
  })

  if (hasNewUtmParams) {
    // Update cookie with new UTM data (merging with existing if needed)
    Cookies.set(cookieName, JSON.stringify(newUtmData), {
      expires: 7,
      path: '/'
    })
  }
}

// Clear the cookie
export const clearUtmCookie = () => {
  Cookies.remove(cookieName, { path: '/' })
}

// Grab a specific UTM value from the stored cookie
export const getUtmParam = (param: string) => {
  const utmData = Cookies.get(cookieName)
  const parsedData = utmData ? JSON.parse(utmData) : null
  return parsedData ? parsedData[param] : undefined
}
